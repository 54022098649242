<template>
    <v-dialog
        v-model="showModal"
        width = "737"
        transition="dialog-transition"
    >
        <v-card  full-width min-height = "565" style="border-radius: 6px;">
            <v-card-title   class="text-center justify-center" style="background: #6868ac; font-weight: bolder; font-size: x-large;" primary-title  height="80px">
                <div style="color: white; font-size: 36px;">ขอความยินยอมบันทึกข้อมูลผู้ใช้งาน</div> 
            </v-card-title>
            <v-card-content >
                <div  class="text-center" style="color: #4E56BE; margin: 8px; font-size: 24px; font-weight:900;" >เว็บไซต์นี้มีการบันทึกข้อมูลการใช้งานของผู้ใช้งาน<br/>
                    เพื่อนำไปปรับปรุงและพัฒนาการให้บริการ</div> 
                <div  class="text-center row justify-center"  style="margin: 4px; font-size: 17px; font-weight:600;">
                    <p style="color: #3E4555; margin-top: auto; margin-bottom: auto;">สรุปรายละเอียดสำคัญในการบันทึกข้อมูล</p>
                </div>
                <div class="row justify-center" style="margin: 4px; font-size: 14px; font-weight:600;">
                    <p style="color: #7E7E7E; margin-top: auto; margin-bottom: auto;">1.บันทึกการเข้าออกระบบ,ระยะเวลาที่ใช้งาน,บริการต่างๆที่มีการเรียกใช้งาน</p>
                </div>
                <div class="row justify-center" style="margin: 4px; font-size: 14px; font-weight:600;">
                    <p style="color: #7E7E7E; margin-top: auto; margin-bottom: auto;">2.บันทึกข้อผิดพลาดในการทำงานต่างๆของระบบเพื่อนำไปปรับปรุงแก้ไข</p>
                </div>
               
                <div class="mt-4 mb-3"  style="margin: 1.25rem; padding:1rem; border-radius: 10px; border: 2px solid #6868AC;">
              
                    <v-row class="align-items-start">
                        <v-col cols="9" sm="9" style="padding: 1.5rem;">
                            <v-row>
                                <div style=" font-size: 14px; font-weight:600;color: #7E7E7E; margin-top: auto; margin-bottom: 1rem;">
                                    ประกาศอ้างอิงว่าด้วยนโยบายคุ้มครองข้อมูลส่วนบุคคล
                                </div>
                            </v-row>
                            <v-row>
                                <div style=" font-size: 12px; font-weight:600; color: #7E7E7E;">
                                    ประกาศมหาวิทยาลัยเชียงใหม่
                                </div>
                            </v-row>
                            <v-row>
                                <div style=" font-size: 12px; font-weight:600;">
                                    <a href="https://privacy.cmu.ac.th/wp-content/uploads/2022/09/%E0%B8%A5%E0%B8%87%E0%B8%99%E0%B8%B2%E0%B8%A1%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A8Privacy-Policy-CMU.pdf" target="_blank" style=" color: #4C54BC; font-weight:500;">เรื่อง นโยบายคุ้มครองข้อมูลส่วนบุคคล มหาวิทยาลัยเชียงใหม่ (CMU Privacy Policy)</a>
                                </div>
                            </v-row>
                            <v-row>
                                <div style="font-size: 12px; font-weight:600;color: #7E7E7E; margin-top: 1rem;">
                                  ประกาศมหาวิทยาลัยเชียงใหม่
                                </div>
                            </v-row>
                            
                            <v-row>
                                <div  style="font-size: 12px; font-weight:600;">
                                    <a href="https://privacy.cmu.ac.th/wp-content/uploads/2022/09/%E0%B8%A5%E0%B8%87%E0%B8%99%E0%B8%B2%E0%B8%A1%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A8PrivacyNotice-CMU.pdf" target="_blank" style=" color: #4C54BC; font-weight:500;">เรื่อง คำประกาศความเป็นส่วนตัว มหาวิทยาลัยเชียงใหม่ (CMU Privacy Notice)</a>
                                </div>
                            </v-row>
                            <v-row>
                                <div  style=" font-size: 12px; font-weight:600;color: #7E7E7E; margin-top: 1rem;">
                                    ประกาศมหาวิทยาลัยเชียงใหม่
                                </div>
                            </v-row>
                            <v-row>
                                <div  style="font-size: 12px; font-weight:600;">
                                    <a href="https://privacy.cmu.ac.th/wp-content/uploads/2022/09/%E0%B8%A5%E0%B8%87%E0%B8%99%E0%B8%B2%E0%B8%A1%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A8-%E0%B9%81%E0%B8%99%E0%B8%A7%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4Data-Controller%E0%B8%81-%E0%B8%8A.pdf" target="_blank" style="color: #4C54BC; font-weight:500;">เรื่อง แนวปฏิบัติสำหรับการดำเนินการของผู้ควบคุมข้อมูลส่วนบุคคลระดับส่วนงาน (Data Controller)</a>
                                </div>
                            </v-row> 
                            
                        </v-col>
                        <v-col cols="3" sm="0" contain>
                            <img width="100%" height="auto" src="../../assets/icon/file.svg"/>
                        </v-col>
                        </v-row>
                </div>
                
            </v-card-content>
            <v-card-actions class="justify-center">
                <v-checkbox id="acceptPDPA" flat color="3E4555" v-model="checkShow"/>
                <div style="color: #3E4555; font-size: 12px;">รับทราบและยินยอมให้มีการบันทึกข้อมูลตามที่ได้รับแจ้ง</div>
                <v-spacer></v-spacer>
                <v-btn flat id="closePDPA" color="#69A5FF" min-width="120px" max-height="32px" @click="onClickAccept"><div style="color: #fff; font-size: 16px;">ตกลง</div></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name : "WelcomeScreenPDPA",
        data: () => ({
            checkShow : false
        }),
        props:{
            showModal  : Boolean
        },
        created(){
         
        },
        methods:{
            onClickAccept(){
                if(this.checkShow){
                    this.$cookie.set('showNotifyPDPA' , 'false' , 30) 
                }         
                this.showModal = false
                this.$emit('onAccept')
            }
        },
    }
</script>