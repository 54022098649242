<template>
    <div >
        <!-- <v-col> -->
            <v-row class="pl-5 pr-5">
                <v-col xl="2" lg="3" md="6" sm="6" xs="4">
                    <v-select
                        style="min-width: 256px;"
                        v-model="termSelected"
                        :items="itemsSelect"
                        label="เลือกเทอม"
                        id="select-term"
                        v-on:change="onSelectSemester()"
                        solo
                    ></v-select>
                
                </v-col>

                <v-spacer></v-spacer>

                <v-col xl="2" lg="3" md="3" sm="3" xs="1" justify-content-end align="end">
                    <v-btn 
                        style="height:48px;"
                        prepend-icon="mdi-magnify"
                        color="#FFFFFF"
                        class="dark--text"
                        @click="CheckAdmin"
                        >
                        <v-icon
                            color="#000"
                            class="mr-1"
                        >mdi-account-multiple</v-icon>
                        <pre class="d-none d-sm-flex">ผู้ดูแลในสังกัด</pre>
                    </v-btn>
                </v-col>
            
            </v-row>
            <v-row class="pb-5 pl-5 pr-5" >
                <v-col v-for="(course, i) in courseLists" :key="i" 	xl="3" lg="4" md="6" sm="12">
                    <course-card :termSelected="termSelected" :regCourseObject="course" :mangoGrant="mangoGrant" :id="`course-card-${i}`" @createTeamsSubmit="onCreateTeamsSubmit" @createCMUExamSubmit="onCreateCMUExamSubmit" @createLmsSubmit="onCreateLmsSubmit" :key="course._id" @checkTeamJob="onCheckTeamJob" @checkLmsJob="onCheckLmsJob" @updateSync="onUpdateSync"></course-card>
                </v-col>
            </v-row>
        <!-- </v-col> -->
    </div>
</template>

<script>
import CourseCard from './CourseCard'
const config = require(`@/config`)

export default {
    name: 'MyCourse',
    props: {
        courseLists: Array,
        currentSemester: String,
        mangoGrant: Boolean
    },
    components: {
        CourseCard
    },
    data: () => ({
        termSelected : localStorage.getItem('termSelected')?localStorage.getItem('termSelected'):config.lmsConfig.currentSemester, 
        itemsSelect: [
            {
                text: 'ลงทะเบียนแบบชั้นปี 2567',
                value: '467',
            }, 
            {
                text: 'ปีการศึกษา 1/2567',
                value: '167',
            },
            {
                text: 'ปีการศึกษา 2/2567',
                value: '267',
            },
            {
                text: 'ปีการศึกษา 3/2567',
                value: '367',
            },
        ],
    }),
    created() {
    },
    computed: {
       
    },

    methods: {
        onSelectSemester(){
            this.$emit('onTermSelectedChange', this.termSelected)
        },
        onCreateTeamsSubmit (sectionArr,courseid , typeCreate) {
            // console.log("My course onCreateTeamsSubmit:", this.termSelected)
            // console.log("My course onCreateTeamsSubmit val2:",courseid)
            this.$emit('createTeamsSubmit', sectionArr, courseid , this.termSelected , typeCreate)
        },
        onCreateLmsSubmit (createData, courseid , obj ) {
            // console.log("MyCourse:>",obj.service , createData, courseid , obj.option)
            this.$emit('createLmsSubmit', createData, courseid, obj , this.termSelected)
        },
        onCreateCMUExamSubmit(sectionArr, courseid , typeCreate){
            // console.log("ggggg" , this.termSelected , typeCreate)
            this.$emit('createCMUExamSubmit', sectionArr, courseid , this.termSelected , typeCreate)
        },
        onCheckTeamJob (courseId, mappingId) {
            this.$emit('checkTeamJob', courseId, mappingId)
        },
        onCheckLmsJob (courseId, mappingId) {
            this.$emit('checkLmsJob', courseId, mappingId)
        },
        onUpdateSync(courseId, status){
            // console.log("onUpdateSync", courseId, status)
            this.$emit('handleUpdateSync', courseId, status)
        },
        clickCard () {
            console.log("click card")
        },
        CheckAdmin(){
            // console.log("check admin")
            this.$emit('CheckAdmin')
        }
        
    },
}
</script>