<template>
  <v-container class="container-small">
    
    <v-alert border="left " color="green"  style="border-radius: 20px" colored-border elevation="2"  v-if="eventDetail.date != ''">
            <v-row align="center"
                justify="center">
                <v-col></v-col>
                <v-col class="text-center" justify-center cols="12" xs="12"  sm="12"  md="6">
                    <v-row>
                        <v-col>
                            <v-icon  color="green">
                                mdi-bell-ring
                            </v-icon>
                            <strong  class="green--text text--lighten-1"> {{eventDetail.date}}</strong >
                            <br/>

                            <h5 class="green--text text--lighten-1">{{eventDetail.eventDetail}}
                                <v-icon  v-if="eventNextDetail.date === ''"  style="margin-top: -0.2rem; width:auto"  @click="handleShowSchedule" color="gray" >
                                    mdi-help-circle
                                </v-icon>
                            </h5 >
                            
                        </v-col>
                        
                    </v-row>
                    <v-divider v-if="eventNextDetail.date != ''" style="margin-top: 0.5rem;"></v-divider>
                    <v-row  style="margin-top: -0.5rem;" v-if="eventNextDetail.date != ''">
                        <v-col>
                            <v-icon  color="gray" style="font-size: 20px;" >
                                mdi-bell-ring
                            </v-icon>
                            <strong  style="font-size: 16px; " class="grey-lighten-5" > {{eventNextDetail.date}}</strong >
                            
                            
                            <h5 class="grey-lighten-2" >  {{eventNextDetail.eventDetail}}
                                <v-icon style="margin-top: -0.2rem;"  @click="handleShowSchedule" color="gray" >
                                    mdi-help-circle
                                </v-icon>
                            </h5 > 
                            
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="text-right">
                
                </v-col>
            </v-row>
        
    </v-alert> 
        
        
   
    <v-card style=" border-radius: 20px!important;">
        <v-tabs
            background-color="#fff"
            color="#745CA8"
            height="80px"
            active-class="active"
            style="padding: 0.5rem;"
            >
            <v-tab href="#cmu" style="font-size: 25px; font-weight: 600;">CMU COURSES</v-tab>
            <!-- <v-tab href="#le" style="font-size: 25px; font-weight: 600; ">LIFELONG COURSES</v-tab> -->
            <v-tab-item value="cmu" style="border-top: 1px solid #C4C4C4 !important;" >
                <v-card >
                    <my-course class="pt-5" :courseLists="courseLists" :currentSemester="currentSemester" :mangoGrant="mangoGrant" @createTeamsSubmit="onCreateTeamsSubmit" @createLmsSubmit="onCreateLmsSubmit" @createCMUExamSubmit="onCreateCMUExamSubmit" @checkTeamJob="onCheckTeamJob" @checkLmsJob="onCheckLmsJob" @onTermSelectedChange="onTermSelectedChange" @handleUpdateSync="onHandleUpdateSync" @CheckAdmin="CheckAdmin"/>
                </v-card>
            </v-tab-item>
            <!-- <v-tab-item value="le"  style="border-top: 1px solid #C4C4C4 !important;">
                <v-card>
                    <MyCourseLE  :courseLists="[]" :currentSemester="currentSemester" :mangoGrant="mangoGrant" @createTeamsSubmit="onCreateTeamsSubmit" @createLmsSubmit="onCreateLmsSubmit" @createCMUExamSubmit="onCreateCMUExamSubmit" @checkTeamJob="onCheckTeamJob" @checkLmsJob="onCheckLmsJob" @onTermSelectedChange="onTermSelectedChange" @handleUpdateSync="onHandleUpdateSync" @CheckAdmin="CheckAdmin"/>
                </v-card>
            </v-tab-item> -->
        </v-tabs>
        
    </v-card>  
    <ModalSchedule :showModal="showSchedule" :dataList="schedule"  @onClose="handleShowSchedule"/>
  </v-container>

</template>

<script>
import MyCourse from './MyCourse'
// import MyCourseLE from './MyCourseLE'
import ModalSchedule from './ModalNotification/ModalSchedule.vue'
import dataEventCpm from '../config/dataEventCpm.json'
// import { useDate } from 'vuetify/labs/date'

export default {
    name: 'Dashboard',
    props: {
        courseLists: Array,
        currentSemester: String,
        mangoGrant: Boolean ,
    },
    components: {
        MyCourse,
        // MyCourseLE , 
        ModalSchedule
    },
    data: () => ({
        items: [
            {
                src: require('../assets/images/cpm-banner-2.png'),
            },
        ],
        regCourses: [] , 
        tabActive : "one" ,
        eventDetail:{
            "date" : "",
            "eventDetail" : ""
        },
        eventNextDetail:{
            "date" : "",
            "eventDetail" : ""
        },
        schedule : dataEventCpm,
        showSchedule : false
    }),
    created() {
        this.checkDate()
        // console.log("checkDate" , dataEventCpm)
    },
    methods: {
        onCreateTeamsSubmit (sectionArr, courseId  , termSelected , typeCreate) {
            // console.log("My course onCreateTeamsSubmit:",sectionArr , courseId)
            // console.log("My course onCreateTeamsSubmit:",courseId)
            this.$emit('createTeamsSubmit', sectionArr, courseId , termSelected , typeCreate)
        },
        onCreateLmsSubmit (createData,courseid , service , termCurrent) {
            this.$emit('createLmsSubmit', createData, courseid , service , termCurrent)
        },
        onCreateCMUExamSubmit(sectionArr,courseId, termSelected ,typeCreate){
            this.$emit('createCMUExamSubmit', sectionArr, courseId , termSelected , typeCreate)
        },
        onCheckTeamJob (courseId, mappingId) {
            this.$emit('checkTeamJob', courseId, mappingId)
        },
        onCheckLmsJob (courseId, mappingId) {
            this.$emit('checkLmsJob', courseId, mappingId)
        },
        onTermSelectedChange (val) {
            this.$emit('onTermSelectedChange', val)
        },
        onHandleUpdateSync(courseId , status){
            this.$emit('onUpdateSyncStatus' ,courseId , status )
        },
        CheckAdmin(){
            this.$emit('CheckAdmin')
        },
        handleShowSchedule(){
            if(this.showSchedule){
                this.showSchedule = false
            }else{
                this.showSchedule = true
            }
        },
        checkDate(){
            for(let i = 0 ; i < this.schedule.length ; i++ ){
                for(let j = 0 ; j < this.schedule[i].termListEvent.length ; j ++){
                    let beforeDateEvent = this.schedule[i].termListEvent[j].dateStart* 1000
                    if(new Date().setDate(new Date().getDate() + 7) >= beforeDateEvent ){
                        // console.log("check date :>" ,this.schedule[i].termListEvent[j] )
                        if(this.schedule[i].termListEvent[j].dateEnd !== null){
                            if(this.schedule[i].termListEvent[j].dateEnd*1000 > new Date().setDate(new Date().getDate() ) ){
                                this.eventDetail.date =   " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j].dateStr 
                                this.eventDetail.eventDetail = this.schedule[i].termListEvent[j].event
                                try{
                                    this.eventNextDetail.date =  " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j+1].dateStr 
                                    this.eventNextDetail.eventDetail = this.schedule[i].termListEvent[j+1].event
                                }catch{
                                    this.eventNextDetail.date =  "" 
                                    this.eventNextDetail.eventDetail = ""
                                }
                            }
                        }else{
                            if(beforeDateEvent >= new Date().setDate(new Date().getDate() -7)  ){
                                this.eventDetail.date =   " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j].dateStr 
                                this.eventDetail.eventDetail = this.schedule[i].termListEvent[j].event
                                try{
                                    this.eventNextDetail.date =  " (" + this.schedule[i].termId + ") " + this.schedule[i].termListEvent[j+1].dateStr 
                                    this.eventNextDetail.eventDetail = this.schedule[i].termListEvent[j+1].event
                                }catch{
                                    this.eventNextDetail.date =  ""
                                    this.eventNextDetail.eventDetail = ""
                                }
                                break
                            }
                        }
                        
                    }
                }
              

                if( this.eventDetail.date != ""){
                    break
                }
            } 
            // console.log("date event :>" , this.eventDetail , this.eventDetail.eventDetail)
        },
    }
}
</script>


<style>
    .v-alert__border {
        border-width: 8px !important;
    }
</style>
