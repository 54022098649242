<template>
    <v-card
        class="mx-auto d-flex flex-column rounded-md"
        max-width="400"
        min-height="300"        
    >
        <v-img
        
          class="dark--text align-end pt-5"
          max-height="100px"
          src="../assets/images/violet-wallpaper.jpg"
        >
          <v-row class="px-4 mt-5" style="background: #F6F2FF;">
              <v-col cols="4" class="text-left pt-3 pb-0" style="white-space: nowrap;">
                  <b style="font-size:1.2rem;font-weight: bold;">Course No. :</b>
              </v-col>
              <v-col  class="text-left pt-3 pb-0 pl-1">
                <p style="font-size:1.2rem;">
                  {{regCourseObject.courseNumber}} 
                </p>
              </v-col>
              <v-col  class="text-right  pb-0 mr-0">
             
              </v-col>
            </v-row>
            <v-row class="px-4" style="height:60px; background: #F6F2FF;">
              <v-col cols="4" class="text-left pt-0 pb-0" style="font-size:14px;font-weight: bold;">
                <p class="mb-0">
                  Course Title :
                </p>
              </v-col>
              <v-col cols="8" class="text-left pt-0 pl-0"  style="font-size:14px;">
                <p class="mb-0">
                {{regCourseObject.title}}
                </p>
              </v-col>
            </v-row>
          <v-spacer></v-spacer>
        </v-img>

        <v-card-text class="text--primary pb-5">
          <v-row >
            <v-col class="p-1" >
              <div>
                <v-btn
                v-if="regCourseObject.services.msteams.length > 0"
                  :key="i"
                  class="ml-2 mt-2 col-3"
                  color="#FFFFFF"
                  max-width="82"
                  elevation="1"
                  @click="clickShowListItems('msteams', regCourseObject._id)"
                >
                  <v-img
                    alt="service-logo"
                    :src="getServiceImage('msteams')"
                    max-height="50"
                    max-width="60"
                    class=""
                  >
                  </v-img>
                </v-btn>
                <v-btn
                  v-if="regCourseObject.services.mango.length > 0"
                  :key="i"
                  class="ml-2 mt-2 col-3"
                  color="#FFFFFF"
                  max-width="82"
                  elevation="1"
                  @click="clickShowListItems('mango' , regCourseObject._id)"
                >
                  <v-img
                    alt="service-logo"
                    :src="getServiceImage('mango')"
                    max-height="50"
                    max-width="60"
                    class=""
                  >
                  </v-img>
                </v-btn>
                <v-btn
                  v-if="regCourseObject.services.moodle.length > 0"
                  :key="i"
                  class="ml-2 mt-2 col-3"
                  color="#FFFFFF"
                  max-width="82"
                  elevation="1"
                  @click="clickShowListItems('moodle', regCourseObject._id)"
                >
                  <v-img
                    alt="service-logo"
                    :src="getServiceImage('moodle')"
                    max-height="50"
                    max-width="60"
                    class=""
                  >
                  </v-img>
                </v-btn>
                <v-btn
                  v-if="regCourseObject.services.exam.length > 0"
                  :key="i"
                  class="ml-2 mt-2 col-3"
                  color="#FFFFFF"
                  max-width="82"
                  elevation="1"
                  @click="clickShowListItems( 'exam', regCourseObject._id)"
                >
                  <v-img
                    alt="service-logo"
                    :src="getServiceImage('exam')"
                    max-height="50"
                    max-width="60"
                    class=""
                  >
                  </v-img>
                </v-btn>
                <v-menu
                  rounded="md"
                  offset-y
                >
                

                  <v-list>
                    <v-list-item
                      v-for="item in itemsCreate"
                      :key="item.module"
                      link
                      :disabled="item.disabled"
                      @click="clickListitem(item.module)"
                    >
                      <v-list-item-title v-text="item.module"></v-list-item-title>
                    </v-list-item>
                  </v-list>
              </v-menu>
              </div>
              
              
              <v-dialog
                v-model="dialogs.msteams"
                max-width="600"
              >
                <v-card class="text-center">
                  <v-card-title>
                    <span>MS Teams List</span>
                    <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="dialogs.msteams = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-2">
                    <v-btn
                      v-for="(items,i) in regCourseObject.services.msteams"
                      :key="i"
                      color="#745CA8"
                      dark
                      outlined
                      class="mb-2"
                      style="width: 500px"
                      @click="checkLmsStatus(items.webUrl)"
                    >
                      {{`${regCourseObject.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogs.canvas"
                max-width="600"
              >
                <v-card class="text-center">
                  <v-card-title>
                    <span>Canvas List</span>
                    <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="dialogs.canvas = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-2">
                    <v-btn
                      v-for="(items,i) in this.regCourseObject.services.canvas"
                      :key="i"
                      color="#745CA8"
                      dark
                      outlined
                      class="mb-2"
                      style="width: 500px"
                      @click="checkLmsStatus(items.webUrl)"
                    >
                      {{`${regCourseObject.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogs.exam"
                max-width="600"
              >
                <v-card class="text-center">
                  <v-card-title>
                    <span>Exam List</span>
                    <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="dialogs.exam = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-2">
                    <v-btn
                      v-for="(items,i) in regCourseObject.services.exam"
                      :key="i"
                      color="#745CA8"
                      dark
                      outlined
                      class="mb-2"
                      style="width: 500px"
                      @click="checkLmsStatus(items.webUrl)"
                    >
                      {{`${regCourseObject.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogs.moodle"
                max-width="600"
              >
                <v-card class="text-center">
                  <v-card-title>
                    <span>Kc-Moodle List</span>
                    <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="dialogs.moodle = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-2">
                    <v-btn
                      v-for="(items,i) in regCourseObject.services.moodle"
                      :key="i"
                      color="#745CA8"
                      dark
                      outlined
                      class="mb-2"
                      style="width: 500px"
                      @click="checkLmsStatus(items.webUrl)"
                    >
                      {{`${regCourseObject.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogs.mango"
                max-width="600"
              >
                <v-card class="text-center">
                  <v-card-title>
                    <span>Mango List</span>
                    <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="dialogs.mango = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-2">
                    <v-div v-for="(items,i) in this.regCourseObject.services.mango" :key="i">
                      <v-btn
                        v-if="items.webUrl !== ''"
                        color="#745CA8"
                        dark
                        outlined
                        class="mb-2"
                        style="width: 500px"
                        @click="checkLmsStatus(items.webUrl)"
                      >
                        {{`${regCourseObject.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                      </v-btn>
                    </v-div>
                   
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-spacer></v-spacer>
        <template>
          <v-btn 
            block
            color="#95A3FF"
            class="white--text"
            max-height="40" 
            @click="handleCourseSpec(regCourseObject._id)">
            
            course spec
          </v-btn>
        </template>
       
    </v-card>
</template>

<script>

  import 'vuetify/dist/vuetify.min.css'

  import Swal from 'sweetalert2'

  export default {
    name: 'CourseCardStudents',
    components: {
    },
    myOptions: {
        items: {
            preSelected: 'unknown',
            labels: [
            { name: 'Off', color: 'white', backgroundColor: 'red' },
            { name: 'unknown', color: 'white', backgroundColor: 'grey' },
            { name: 'On', color: 'white', backgroundColor: 'green' }
            ]
        }
    },
    props: {
      regCourseObject: Object,
      mangoGrant: Boolean
    },
    created() {
      // console.log("toggle status:>" , this.syncStatus)
      // console.log("regCourseObject:",this.regCourseObject)
      this.checkToggleStatus(this.regCourseObject)
      
    },
    data: () => ({
      value1:null,
      dialogs: {
        msteams: false,
        canvas: false,
        moodle: false,
        mango: false,
        exam: false
      },
      loadingLists:{
        msteams: false,
        canvas: false,
        moodle: false,
        mango: false,
        exam: false
      },
      loadingTeamsList: false,
      loadingLmsList: false,
      loadingCMUExam: false,
      itemsCourse: ['Course 1', 'Course 2'],
      syncStatus : "yes",
      btns: [
        ['Large', 'lg'],
      ],
      colors: ['deep-purple accent-4', 'error', 'teal darken-1'],
      items: [...Array(4)].map((_, i) => `Item ${i}`),
      itemsCreate:[
        {
          "module": "LMS",
          "disabled": false
        },
        {
          "module": "MS Teams",
          "disabled": false
        },
        {
          "module": "Grade Book",
          "disabled": true
        },
        {
          "module": "Exam Manager",
          "disabled": true
        },
        {
          "module": "CMU Exam",
          "disabled": false
        }
      ],
      itemsSetting:[
        {
          "module": "DASHBOARD",
          "disabled": false
        },
        // {
        //   "module": "SYNC STUDENTS",
        //   "disabled": false
        // }
      ],
      openCreateModal: {
        msteams: false,
        lms: false,
        cmu_exam: false
      }
    }),
    watch:{
      syncStatus(){
        // this.syncStatus = !val
        // console.log("toggle :>" , val);
      },
    },
    computed:{
      
      currentSemester() {
        return this.$store.state.currentSemester
      }
      
    },
    methods: {
      checkToggleStatus(courseObj){
        // console.log("courseObj" , courseObj)
        if(courseObj.mapping){
          let count_service = 0;
          for(const service in courseObj.mapping){
            // console.log("service:>" , service)
            let count_sync = 0;
            for(let i = 0 ; i < courseObj.mapping[service].length ; i++){
              // console.log(service,"courseObj.mapping[service][i].syncEnable :>" , courseObj.mapping[service][i].syncEnable )
              if(courseObj.mapping[service][i].syncEnable === false){   
                this.syncStatus = '';
                count_sync= count_sync + 1;
              }
            }
            if(count_sync >= courseObj.mapping[service].length){
              count_service = count_service + 1;
            }
          }
          // console.log("count:>",Object.keys(courseObj.mapping).length , count_service)
          if(count_service >= Object.keys(courseObj.mapping).length){
            this.syncStatus = 'no';
          }
          // console.log("courseObj sync status:>" , courseObj.title , ":",this.syncStatus)
        }
      },
      clickState(courseId , val){
        
        this.onToggleSync(courseId , String(val))
      },
      goToCourse (courseId) {
        this.$router.push(`/courses/${courseId}`)
      },
      getServiceImage (service) {
        // console.log(service)
        let images = ""
        switch (service) {
          case "msteams":
            images = require.context('../assets/images', false, /\.svg$/)
            return images('./' + 'microsoft-teams-logo-crop' + ".svg")
          case "canvas":
            images = require.context('../assets/images', false, /\.png$/)
            return images('./' + 'canvas-logo-crop' + ".png")
          case "moodle":
            images = require.context('../assets/images', false, /\.png$/)
            return images('./' + 'moodle-logo-crop' + ".png")
          case "mango":
            images = require.context('../assets/images', false, /\.png$/)
            return images('./' + 'mango-logo-crop' + ".png")
          case "exam":
            images = require.context('../assets/images', false, /\.png$/)
            return images('./' + 'exam-logo' + ".png")
          default:
            break;
        }
        // return "../assets/images/canvas-logo-crop.png"
        // let images = require.context('../assets/images', false, /\.svg$/)
        // return images('./' + 'canvas-logo-crop' + ".png")
        // return images('./' + 'microsoft-teams-logo-crop' + ".svg")
      },
      onModalTeamsSubmit (val) {
        // console.log('test click create team' , val)
        this.$emit('createTeamsSubmit', val, this.regCourseObject._id)
      },
      onModalLmsSubmit (val  , service) {
        // console.log("Submit :>", val , service)
        this.$emit('createLmsSubmit', val, this.regCourseObject._id , service)
      },
      onModalCMUExamSubmit(val){
        // onCreateCMUExam
        this.$emit('createCMUExamSubmit', val, this.regCourseObject._id)

      },
      clickListitem (val) {
        // console.log("clickListitem:",val)
        switch (val) {
          case 'MS Teams':
            this.openCreateModal.msteams = true
            break
          case 'LMS':
            this.openCreateModal.lms = true
            break
          default:'CMU Exam'
            // console.log('Click CMU', this.openCreateModal)
            this.openCreateModal.cmu_exam = true
            break
        }
      },
      onToggleSync(courseId , val){
        if(val === "yes"){
          Swal.fire({
              icon: 'info',
              title: 'ท่านต้องการเปิดการซิงค์รายชื่ออัตโนมัติ',
              html: '',
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: 'ตกลง',
              cancelButtonText: 'ยกเลิก',
          }).then((result) => {
              if (result.isConfirmed) {
                let statusSync = val === "yes"? true : false
                this.$emit('updateSync', courseId , statusSync)
                this.syncStatus = "yes"
              }
          })
        }else{
          Swal.fire({
              icon: 'info',
              title: 'ท่านต้องการปิดการซิงค์รายชื่ออัตโนมัติ',
              html: '',
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: 'ตกลง',
              cancelButtonText: 'ยกเลิก',
          }).then((result) => {
              if (result.isConfirmed) {
                // updateSync
                let statusSync = val === "yes"? true : false
                this.$emit('updateSync', courseId , statusSync)
                this.syncStatus = "no"
              }
          })
        }
       
      },
     
      clickShowListItems (service, id) {
        // console.log(service)
        switch (service) {
          case "msteams":
            this.clickGoToTeams(id)
            break
          case "canvas":
            this.clickGoToCanvas(id)
            break
          case "moodle":
            this.clickGoToMoodle(id)
            break
          case "mango":
            this.clickGoToMango(id)
            break
          case "exam":
            this.clickGoToExam(id)
            break
          default:
            break
        }
      },
      clickGoToTeams (id) {
        this.loadingLists.msteams = true
        this.dialogs.msteams = true
        console.log("clickGoToTeams",id)
      },
      clickGoToCanvas (id) {
        this.loadingLists.canvas = true
        this.dialogs.canvas = true
        console.log("clickGoToCanvas",id)
      },

      clickGoToExam (id) {
        this.loadingLists.exam = true
        this.dialogs.exam = true
        console.log("clickGoToExam",id)
      },
      clickGoToMoodle (id) {
        this.loadingLists.moodle = true
        this.dialogs.moodle = true
        console.log("clickGoToMoodle",id)
      },
      clickGoToMango (id) {
        this.loadingLists.mango = true
        this.dialogs.mango = true
        console.log("clickGoToMango",id)
      },
      checkTeamsStatus (courseId, mappingId) {
        console.log("checkTeamsStatus:",courseId, mappingId)
        this.$emit('checkTeamJob', courseId, mappingId)
      },
      checkLmsStatus (webUrl) {
        this.$emit('checkLmsJob', webUrl)
      },
      clickListItemSetting (val, id) {
        switch (val) {
          case 'DASHBOARD':
            this.goToCourse(id)
            break
          default:
            this.goToCourse(id)
            break
        }
      },
      sectionAfterTitleName (sectionArr) {
        // console.log("section", sectionArr)
        for(let i=0;i<sectionArr.length;i++){
            sectionArr[i] = this.filterSection(sectionArr[i])
        }
        return sectionArr
      },
      handleCourseSpec(regId){
        this.$emit('CourseSpec', regId)
      },
      filterSection (section) {
        // console.log("filter section:",section)
        let sectionLec = section.substring(0, 3)
        let sectionLab = section.substring(3)
        if (sectionLec === '000' && sectionLab !== '000') {
          return `Lab.${sectionLab}`
        }else if(sectionLec !== '000' && sectionLab === '000'){
          return `Lec.${sectionLec}`
        }
        return section
      },
    },
  }
</script>
