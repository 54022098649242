<template>
    <v-dialog
        v-model="showModal"
        width = "737"
        transition="dialog-transition"
    >
        <v-card class="text-center"  full-width max-height = "545" style="border-radius: 6px;">
            <v-card-title style="background: #6868ac; font-weight: bolder; font-size: x-large;" primary-title class="justify-center" height="80px">
                <div style="color: white; font-size: 36px;">ประกาศสำคัญ</div> 
            </v-card-title>
            <v-card-content>
                <div style="color: #4E56BE; margin: 8px; font-size: 24px; font-weight:900;" >ตั้งแต่ภาคการศึกษาที่ 2 ของปีการศึกษา 2567</div> 
                <div class="row justify-center" style="margin: 4px; font-size: 17px; font-weight:600;">
                    <p style="color: #3E4555; margin-top: auto; margin-bottom: auto;">จะ</p>
                    <p style="color: #D34264; margin-top: auto; margin-bottom: auto;">ไม่สามารถสร้าง Microsoft Teams</p>
                    &nbsp;
                    <p style="color: #3E4555; margin-top: auto; margin-bottom: auto;">ผ่านระบบ</p> 
                    &nbsp;
                    <p style="color: #D34264; margin-top: auto; margin-bottom: auto;">Course Portal</p> 
                    &nbsp;
                    <p style="color: #3E4555; margin-top: auto; margin-bottom: auto;">ได้</p>
                </div>
                <div class="row justify-center" style=" color: #7E7E7E; margin: 4px; font-size: 14px; font-weight:400;">
                    <u >**อ้างอิงจากประกาศของสำนักบริการเทคโนโลยีสารสนเทศมหาวิทยาลัยเชียงใหม่ในการยกเลิก&nbsp;</u><a href="https://itsc.cmu.ac.th/th/articleview/ca3cf7c7-0b5e-4994-a246-1bea00be2dfc" target="_blank" style=" color: #4C54BC; font-weight:500;"> Microsoft team</a>
                </div>
                <div class="mt-4 mb-3" >
                    <a href="https://itsc.cmu.ac.th/en/articleview/ca3cf7c7-0b5e-4994-a246-1bea00be2dfc" target="_blank">
                        <v-img style="margin-left: auto; margin-right: auto;" max-width="647" max-height="254" src="../../assets/images/MS Team pop up@4x-100.jpg"></v-img>
                    </a>
                </div>
                
            </v-card-content>
            <v-card-actions class="justify-center">
                <v-checkbox id="acceptNotify" flat color="3E4555" v-model="checkShow"/>
                <div style="color: #3E4555; font-size: 12px;">ไม่ต้องแสดงข้อความนี้อีก</div>
                <v-spacer></v-spacer>
                <v-btn flat id="closeNotify" color="#69A5FF" min-width="120px" max-height="32px" @click="onClickAccept"><div style="color: #fff; font-size: 16px;">รับทราบ</div></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name : "WelcomeScreen",
        data: () => ({
            checkShow : false
        }),
        props:{
            showModal  : Boolean
        },
        created(){
         
        },
        methods:{
            onClickAccept(){
                if(this.checkShow){
                    this.$cookie.set('showNotify' , 'false' , 30) 
                }         
                this.showModal = false
                this.$emit('onAccept')
            }
        },
    }
</script>